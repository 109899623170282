import { ExtractedDependingValue } from '@components/HubspotSupportForm/HubspotSupportForm';
import * as yup from 'yup';

export const validateDependentField = (
  dependentFieldValue: ExtractedDependingValue,
  parentVal: ExtractedDependingValue,
  schema: yup.BaseSchema,
  requiredMessage: string,
) => {
  if (
    (Array.isArray(dependentFieldValue) &&
      dependentFieldValue.findIndex(val => val === parentVal) !== -1) ||
    dependentFieldValue === parentVal
  ) {
    return yup.string().required(requiredMessage);
  }
  return schema;
};

export const validatePhoneNumber = (value: number) => {
  if (!value) {
    return true;
  }

  return !isNaN(value);
};
