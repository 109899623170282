import { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

import { getFirstDayOfMonth, getLastDayOfMonth, useStatsList } from 'utils';
import { ContractStatus, FetchingStatus } from 'types';
import { StatsActions } from 'features/stats/store';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';

import { StatsWrapper } from './Stats.styles';
import { useIntl } from 'react-intl';
import { ContractsSelectors } from 'features/contract/store/contracts.selectors';
import Spacer from '@components/Spacer';
import StatsCard from '@features/stats/components/StatsCard';

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();

const StatsPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const statisticsData = useAppSelector(state => state.stats);
  const { coupons, activeMembers, checkIns } = statisticsData;
  const isLoading = [
    coupons.fetchingStatus,
    activeMembers.fetchingStatus,
    checkIns.fetchingStatus,
  ].includes(FetchingStatus.PENDING);
  const contractsApi = useAppSelector(ContractsSelectors.getContracts);
  const contracts = contractsApi.getContracts.data;
  const isFullAccess = contracts.some(
    contract =>
      contract.status === ContractStatus.Active &&
      contract.products.filter(item => item.name === 'Online+').length > 0,
  );

  const getCheckInsList = async () => {
    const startDate = getFirstDayOfMonth(currentYear, currentMonth);
    const endDate = getLastDayOfMonth(currentYear, currentMonth);

    dispatch(
      StatsActions.getActiveMembers({
        companyId: activeCompany?.companyId,
        startMonth: startDate,
        endMonth: endDate,
      }),
    );
    dispatch(
      StatsActions.getCheckIns({
        companyId: activeCompany?.companyId,
        startMonth: startDate,
        endMonth: endDate,
      }),
    );
    if (isFullAccess) {
      dispatch(
        StatsActions.getCoupons({
          companyId: activeCompany?.companyId,
          startMonth: startDate,
          endMonth: endDate,
        }),
      );
    }
  };

  useEffect(() => {
    if (activeCompany && activeCompany.companyId) {
      getCheckInsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId]);

  const statsList = useStatsList();
  const availableStatsList = isFullAccess
    ? statsList
    : statsList.filter(el => el.name !== 'coupons');
  const renderStatsList = availableStatsList.map(item => {
    const data = statisticsData?.[item.name as keyof typeof statisticsData];
    const statisticCount = data?.data?.length || 0;

    const hasError = data?.fetchingStatus === FetchingStatus.REJECTED;

    return <StatsCard key={item.title} item={item} count={statisticCount} hasError={hasError} />;
  });

  return (
    <StatsWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'navigation.stats', defaultMessage: 'Statistics' })}
      </Typography>
      <Spacer size='sm' />
      <Typography variant='caption'>
        {intl.formatMessage({
          id: 'view.stats.title',
          defaultMessage: 'View stats summary for the current month.',
        })}
      </Typography>
      <Spacer size='lg' />
      {isLoading && (
        <Box className='loadingWrapper'>
          <CircularProgress size={40} />
          <Spacer size='lg' />
          <Typography variant='h3'>
            {intl.formatMessage({
              id: 'view.stats.loading.title',
              defaultMessage: 'Loading statistics',
            })}
          </Typography>
          <Spacer size='md' />
          <Typography variant='caption'>
            {intl.formatMessage({
              id: 'view.stats.loading.content',
              defaultMessage: 'Please, wait for a moment…',
            })}
          </Typography>
        </Box>
      )}
      {!isLoading && <Box className='cards'>{renderStatsList}</Box>}
    </StatsWrapper>
  );
};

export default StatsPage;
