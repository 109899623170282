export const formattedPrice = (price: number) =>
  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price);

// export const formattedPriceIntl = (price: string, intl: IntlShape) =>
//   intl
//     .formatNumber(parseFloat(price), {
//       style: 'currency',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//       currency: 'EUR',
//       currencyDisplay: 'code',
//     })
//     .replace('EUR', '')
//     .trim();
