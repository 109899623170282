import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { SerializedError, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  RootState,
  SignInMFAParams,
  SignInRequestParams,
  UserManagementBaseResponse,
} from '@localTypes/index';
import { MFAStateActions } from '@store/mfaState';

const signIn = createAsyncThunk<
  CognitoUser,
  SignInRequestParams | SignInMFAParams,
  { state: RootState; rejectValue: SerializedError }
>('authentication/signIn', async (params, { rejectWithValue, getState, dispatch }) => {
  try {
    if (params.hasOwnProperty('email')) {
      const { email, password } = params as SignInRequestParams;
      const response = await Auth.signIn(email, password);
      Sentry.setUser({ email });
      if (response.challengeName === 'CUSTOM_CHALLENGE') {
        dispatch(
          MFAStateActions.setMFAData({
            user: response,
            email,
            password,
            timestamp: new Date().getTime(),
          }),
        );
      }
      return response;
    } else {
      const { code } = params as SignInMFAParams;
      const state = getState();
      const challengeResponse = await Auth.sendCustomChallengeAnswer(state.MFAState?.user, code);
      if (state.MFAState && challengeResponse.challengeName === 'CUSTOM_CHALLENGE') {
        dispatch(MFAStateActions.setErrorMFACode('INVALID_MFA'));
      } else {
        dispatch(MFAStateActions.resetMFAData());
      }
      return challengeResponse;
    }
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;

    return rejectWithValue({ message: error.message, code: error.code });
  }
});

export default signIn;
