import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import theme from '@theme/theme';

type GuardRegistrationDeadlineProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

const GuardRegistrationDeadline = ({ onCancel }: GuardRegistrationDeadlineProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open onClose={onCancel}>
      <CloseIcon onClick={onCancel} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>
          {formatMessage({
            id: 'router.leaving_signup.dialog.title',
            defaultMessage: 'No deadline for registrations selected',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {formatMessage({
            id: 'router.leaving_signup.dialog.subtitle',
            defaultMessage: `To set a deadline for new registrations for the next month, please select a date.`,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={onCancel} variant='contained'>
          {formatMessage({
            id: 'router.leaving_signup.cta.confirm',
            defaultMessage: 'Select date',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default GuardRegistrationDeadline;
