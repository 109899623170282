import { useState, useEffect, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, SelectChangeEvent } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

import { useAppDispatch } from 'store';
import { CompanyActions } from 'features/company/store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { navigationList, generateKey } from 'utils';
import { ReactComponent as HansefitLogoIcon } from 'assets/icons/hansefit-logo-icon.svg';
import { ReactComponent as HansefitLogoSmall } from 'assets/icons/hansefit-logo-small.svg';
import { CompanyRole, IAuthorizedSideBar, CompaniesNamesResponse } from 'types';

import SideBarItem from './SideBarItem';
import { SelectWrapper, MenuItemWrapper } from './LeftSideBar.styles';
import Loader from 'components/Loader';
import { useLanguage } from 'hooks';
import Spacer from '@components/Spacer';

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 6,
    },
  },
};

const AuthorizedSideBar: React.FC<IAuthorizedSideBar> = ({ open, openModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeLanguage = useLanguage();
  const { formatMessage } = useIntl();
  const allCompaniesValue: string = 'All companies';

  const { userInfo } = useSelector(AuthenticationSelectors.getAuthentication);

  const { companiesNames, activeCompany: activeCompanyState } = useSelector(
    CompanySelectors.getCompanyState,
  );

  const companies: CompaniesNamesResponse[] = useMemo(
    () => companiesNames.data || [],
    [companiesNames.data],
  );
  const isSelect = useMemo(() => open && companies.length > 1, [open, companies]);

  const [isOpen, setIsOpen] = useState(false);
  const [activeCompany, setActiveCompany] = useState(allCompaniesValue);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const localStorageActiveCompanyId = localStorage.getItem('activeCompanyId');
    const activeCompanyId = !localStorageActiveCompanyId
      ? location.pathname.slice(location.pathname.lastIndexOf('/') + 1)
      : localStorageActiveCompanyId;

    if (!isNaN(parseInt(activeCompanyId))) {
      setActiveCompany(activeCompanyId);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (activeCompanyState) {
      const activeCompanyId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
      if (location.pathname.split('/').includes('dashboard') && !isNaN(parseInt(activeCompanyId))) {
        setActiveCompany(activeCompanyId);
      } else if (location.pathname.split('/').includes('dashboard')) {
        setActiveCompany(allCompaniesValue);
      } else if (activeCompanyState.companyId) {
        const activeCompanyStateId = activeCompanyState.companyId.toString();
        if (activeCompanyStateId && activeCompanyStateId !== activeCompanyId) {
          setActiveCompany(activeCompanyStateId);
        }
      }
    }
  }, [activeCompanyState, location.pathname]);

  useEffect(() => {
    if (companiesNames.data.length > 1 && location.pathname.includes('/account')) {
      setActiveCompany(allCompaniesValue);
    } else if (
      activeCompanyState &&
      location.pathname.includes('/user-management') &&
      activeCompany !== activeCompanyState.companyId.toString()
    ) {
      setActiveCompany(activeCompanyState.companyId.toString());
    }
  }, [location, companiesNames, activeCompanyState, activeCompany]);

  const selectToggler = (): void => {
    setIsOpen(!isOpen);
  };

  const goToDashboard = () => {
    if (location.pathname.includes('/account')) {
      dispatch(CompanyActions.updateActiveCompany(null));
      setActiveCompany(allCompaniesValue);
      navigate(`/${activeLanguage}/dashboard`);
    }
  };

  const updateActiveCompanyHandler = useCallback(
    (event: SelectChangeEvent<unknown>): void => {
      setShowLoader(true);
      //it is a temporary solution,because now we have no any API updates after change company
      setTimeout(() => setShowLoader(false), 1000);
      const { value } = event.target;
      const filterActiveCompany = companies.find(item => item.companyId === value);
      setActiveCompany(value as string);
      dispatch(CompanyActions.getCompany({ companyId: parseInt(value as string) }));

      if (filterActiveCompany) {
        dispatch(CompanyActions.updateActiveCompany(filterActiveCompany));
        navigate(`/${activeLanguage}/dashboard/${value}`);
      } else {
        dispatch(CompanyActions.updateActiveCompany(null));
        navigate(`/${activeLanguage}/dashboard`);
      }
    },
    [activeLanguage, companies, dispatch, navigate],
  );

  const updateActiveIndexHandler = (_: number, link: string): void => {
    if (activeCompanyState && link === navigationList[0].link) {
      navigate(`/${activeLanguage}/${link}/${activeCompanyState.companyId}`);
    } else {
      navigate(`/${activeLanguage}/${link}`);
    }
  };

  const handleLogoClick = () => {
    if (companiesNames?.data?.length > 1) {
      dispatch(CompanyActions.updateActiveCompany(null));
      setActiveCompany(allCompaniesValue);
      navigate(`/${activeLanguage}/dashboard`);
      return;
    }
    if (activeCompanyState) {
      navigate(`/${activeLanguage}/dashboard/${activeCompanyState.companyId}`);
    }
  };

  const renderNavigationList = navigationList.map((item, index) => {
    if (activeCompany === allCompaniesValue && item.text !== 'Support') {
      return null;
    }

    if (item.text === 'Company profile') {
      return (
        <SideBarItem
          key={item.id}
          item={item}
          open={open}
          index={index}
          updateActiveIndex={updateActiveIndexHandler}
        />
      );
    }

    //needs to be fixed
    const navigationRoles = userInfo
      ? userInfo.role?.map((role: CompanyRole) => {
          if (item.role.length === 0 || item.role.includes(role)) {
            return (
              <SideBarItem
                key={item.id}
                item={item}
                open={open}
                index={index}
                updateActiveIndex={updateActiveIndexHandler}
              />
            );
          }

          return null;
        })
      : null;

    if (item.role.length === 0) {
      return (
        <SideBarItem
          key={item.id}
          item={item}
          open={open}
          index={index}
          updateActiveIndex={updateActiveIndexHandler}
        />
      );
    }

    return navigationRoles;
  });

  const renderSelectItems = useMemo(() => {
    if (!companies || companies.length === 0) {
      return null;
    }

    // Create a copy of the read-only array before sorting
    const sortedCompanies = [...companies].sort((a, b) =>
      a.companyName.localeCompare(b.companyName),
    );

    return sortedCompanies.map(item => (
      <MenuItemWrapper
        key={generateKey(`${item.companyId}-${item.companyName}`)}
        value={item.companyId}
        className='notranslate'>
        {item.companyName}
      </MenuItemWrapper>
    ));
  }, [companies]);

  return (
    <>
      {
        //need to remove Loader from markup because in the loader we have a cleanup fucntion to rollback the scrollbar to the body
      }
      {showLoader && <Loader show={showLoader} />}
      <Box className='header'>
        <HansefitLogoSmall className='header__logo' onClick={handleLogoClick} />
        <HansefitLogoIcon className='header__logo_small' onClick={handleLogoClick} />
      </Box>
      <Box data-test-id='sideMenuContainer'>
        {open && <Spacer size='xl' />}
        <Typography className='header__logo_text' variant='h4'>
          {formatMessage({ id: 'company.logo.text', defaultMessage: 'Customer Portal' })}
        </Typography>
        {isSelect && (
          <Box className='company__select'>
            <SelectWrapper
              fullWidth
              value={activeCompany}
              isOpen={isOpen}
              onOpen={selectToggler}
              onClose={selectToggler}
              onChange={updateActiveCompanyHandler}
              MenuProps={MenuProps}
              className='notranslate'
              data-test-id='companySelectorInput'>
              <MenuItemWrapper value='All companies' onClick={goToDashboard}>
                {formatMessage({ id: 'all_companies', defaultMessage: 'All companies' })}
              </MenuItemWrapper>
              {renderSelectItems}
            </SelectWrapper>
          </Box>
        )}
        <Box className='wrapper'>{renderNavigationList}</Box>
        <Box className='back_wrapper'>
          <Typography className='back_text' variant='body1'>
            {formatMessage({
              id: 'navigation.hide_menu',
              defaultMessage: 'Hide menu',
            })}
          </Typography>
          <Box className='back__icon' onClick={openModal}>
            <ArrowBackIosOutlinedIcon fontSize='small' />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuthorizedSideBar;
