import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { EmployeeLicense, GetEmployeeLicenseParam } from 'types';

export const getEmployeeLicense = createAsyncThunk<EmployeeLicense[], GetEmployeeLicenseParam>(
  'employeeManagement/getEmployeeLicense',
  async ({ employeeId }, { rejectWithValue }) => {
    const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers/${employeeId}/licenses`;
    const axios = await axiosWithConfig();
    try {
      return (await axios.get(endpoint)).data;
    } catch (e) {
      const error: AxiosError<EmployeeLicense> = e as AxiosError<EmployeeLicense>;

      return rejectWithValue(error.response?.data);
    }
  },
);
