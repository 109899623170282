import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {
  setActivationLettersPopupState,
  showActivationLettersAddUserPopup,
  setRegistrationDeadlinePopupState,
} from './actions/sync/routerGuard.actions';

const globalStateSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    setActivationLettersPopupState,
    showActivationLettersAddUserPopup,
    setRegistrationDeadlinePopupState,
  },
});

export const { name, actions } = globalStateSlice;
export const globalStateReducer = globalStateSlice.reducer;
