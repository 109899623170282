import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import {
  UserManagementBaseResponse,
  GetFaqsRequestParams,
  GetFaqsResponse,
  RootState,
} from 'types';

export const getFaqs = createAsyncThunk<
  GetFaqsResponse,
  GetFaqsRequestParams,
  { state: RootState }
>('statistics/getFaqs', async ({ companyId, language }, { rejectWithValue, getState }) => {
  const state = getState();
  companyId =
    companyId ||
    (state.company.companiesNames.data.length === 1
      ? state.company.companiesNames.data[0].companyId
      : state.company.activeCompany?.companyId);

  const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${companyId}/faqs`;
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.get(endpoint, { params: { language } });

    return data;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;
    showToast('error', {
      id: 'toast.faqs.get.error',
      defaultMessage: error.message,
    });
    return rejectWithValue(error.response?.data);
  }
});
