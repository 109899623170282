import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse, CouponTableProps, GetCheckInsRequestParams } from 'types';

export const getCoupons = createAsyncThunk<CouponTableProps[], GetCheckInsRequestParams>(
  'stats/getCoupons',
  async ({ companyId, startMonth, endMonth }, { rejectWithValue }) => {
    const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}graphql`;
    const body = {
      query: `
					{
						CompanyCoupons(filter:{company_id:{eq:"${companyId}"},date:{gte:"${startMonth}",lte:"${endMonth}"}}){
							start_month, end_month
							data { partner_id, partner_name, coupons, eus }
						}
					}`,
    };
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.post(endpoint, body);

      return data.data.CompanyCoupons.data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.coupons.get.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
