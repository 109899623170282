import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbContainer } from './BreadcrumbContainer.styles';

export const Breadcrumb: React.FC<{ routes: { url: string; text: string }[] }> = ({ routes }) => (
  <BreadcrumbContainer>
    {routes.map((route, idx) =>
      idx === routes.length - 1 ? (
        <Typography key={route.url} variant='subtitle2' className='breadcrumb__item active'>
          {route.text}
        </Typography>
      ) : (
        <React.Fragment key={route.url}>
          <Typography variant='subtitle2' className='breadcrumb__item'>
            <Link to={route.url}>{route.text}</Link>
          </Typography>
          <Box className='breadcrumb__divider'>|</Box>
        </React.Fragment>
      ),
    )}
  </BreadcrumbContainer>
);
