import { AxiosError } from 'axios';
import { axiosWithConfig } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { RootState, UpdateUserRequestBody } from 'types';
import { AuthenticationActions } from 'features/authentication/store';
import { UserManagementBaseResponse } from 'api/interfaces';

export const updateUser = createAsyncThunk<{}, UpdateUserRequestBody, { state: RootState }>(
  'authentication/updateUser',
  async (body, { rejectWithValue, dispatch, getState }) => {
    const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + 'changeOwnUserData';
    const axios = await axiosWithConfig();

    try {
      const currentState = getState();
      if (body.companyId) {
        await axios.put(endpoint, body);
      } else {
        //update all users for all companies, because we need to have only one entity, but in dynamoDB we have separate entities for each company
        await Promise.all(
          currentState.company.companiesNames.data.map(company =>
            axios.put(endpoint, { ...body, companyId: String(company.companyId) }),
          ),
        );
      }

      showToast('success', {
        id: 'toast.account.profile.changed',
        defaultMessage: 'User information <span>has been changed</span>',
      });
      const currentCompanyId =
        currentState.company.activeCompany?.companyId ||
        currentState.company.companiesNames.data[0].companyId;
      await dispatch(
        AuthenticationActions.getUserInfo({
          companyId: body.companyId ? Number(body.companyId) : currentCompanyId,
        }),
      );

      return {};
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      showToast('error', {
        id: 'toast.update_user.error',
        defaultMessage: error.message,
      });
      rejectWithValue(error.response?.data);
    }
  },
);
