import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import ErrorSvg from '@mui/icons-material/Error';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import {
  CCPDatePicker,
  CCPDefaultButton,
  CCPSelect,
  CCPTextField,
  ModalError,
  Spacer,
} from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { format } from 'date-fns';
import { AddEmployeeForm } from './interfaces';
import { AddEmployeeSchema } from 'shared/schemas';
import { useDialCodes, ValidationUtils } from 'utils';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { FetchingStatus, PimcoreErrorResponse } from 'types';
import { CompanyActions } from 'features/company/store';
import { useAsyncViolationFeedback } from 'hooks';
import { useIntl } from 'react-intl';
import { useGendersList } from 'utils/genders';
import { CCPLabelCheckbox } from 'components/Form/Checkbox/LabelCheckbox';
import { useEmployeeTitles } from 'utils/employeeTitle';
import * as yup from 'yup';
import {
  checkTypeParameter,
  getDate,
  checkIsMonthPicker,
  prepareMaxDateForStartPicker,
  prepareMinDateForStartPicker,
} from 'utils/contractDependencies';
import { getCustomFieldValidationObject } from 'utils/customField';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import StartDatePicker from '@components/Form/StartDatePicker';
import { getBirthDate } from '@utils/getUpdatedDate';
import { default as theme } from '@theme/theme';
import Loader from '@components/Loader';
import { PersonalFieldsWrapper } from '@features/employeeManagement/components/EmployeeBulkUpload/DialogStyled';
import { EmployeeManagementSelectors } from '@features/employeeManagement/store/employeeManagement.selectors';
import { EmployeeManagementActions } from '@features/employeeManagement/store';
import GlobalCustomFields from '../../GlobalCustomFields';
import CustomFields from '../../CustomFields';

const AddEmployeeModal: React.FC<{
  checkActivationLettersSettingsIsCorrect: () => boolean;
}> = ({ checkActivationLettersSettingsIsCorrect }) => {
  const { formatMessage } = useIntl();
  const { language } = useParams();
  const dispatch = useAppDispatch();
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const addEmployeeModalOpen = useAppSelector(EmployeeManagementSelectors.addEmployeeModalOpen);
  const companyApi = useAppSelector(CompanySelectors.getApi);
  const employeeApi = useAppSelector(EmployeeManagementSelectors.getApi);
  const { activeCompany, companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const company = companyApi.getCompany;
  const addEmployee = employeeApi.addEmployee;
  const dialCodes = useDialCodes();
  const customFields = useMemo(
    () => company?.data?.customFields || [],
    [company?.data?.customFields],
  );
  const globalCustomFields = useMemo(
    () => company?.data?.globalCustomFields || [],
    [company?.data?.globalCustomFields],
  );
  const customFieldsNames: any = {};
  customFields.forEach(field => (customFieldsNames[`customFields.${field.key}`] = ''));

  const globalCustomFieldsNames: any = {};
  globalCustomFields.forEach(
    field => (globalCustomFieldsNames[`globalCustomFields.${field.key}`] = ''),
  );

  const isMandatoryEmail = useMemo(
    () => settingsSettings && settingsSettings.activationLetterRecipientMode.toLowerCase() === 'eu',
    [settingsSettings],
  );

  const schema: any = useMemo(() => {
    let newSchema: any = {};
    let AddEmployeeShapedSchema = AddEmployeeSchema(prepareMaxDateForStartPicker(activeContract));
    if (isMandatoryEmail) {
      AddEmployeeShapedSchema = AddEmployeeShapedSchema.shape({
        businessEmail: yup
          .string()
          .required('form.employee.error.corporate_email.required')
          .trim()
          .email('form.employee.error.corporate_email.invalid'),
      });
    }

    const mandatoryCustomField = customFields;
    if (mandatoryCustomField.length) {
      mandatoryCustomField.forEach(field => {
        newSchema[field.key] = getCustomFieldValidationObject(field);
      });
      AddEmployeeShapedSchema = AddEmployeeShapedSchema.shape({
        customFields: yup.object().shape({ ...newSchema }),
      });
    }
    const mandatoryGlobalCustomField = globalCustomFields.filter(
      field => field.mandatory === 'yes',
    );
    if (mandatoryGlobalCustomField.length) {
      newSchema = {};
      mandatoryGlobalCustomField.forEach(field => {
        newSchema[field.key] = yup.string().required('form.employee.error.custom_field.required');
      });
      AddEmployeeShapedSchema = AddEmployeeShapedSchema.shape({
        globalCustomFields: yup.object().shape({ ...newSchema }),
      });
    }

    return AddEmployeeShapedSchema;
  }, [customFields, globalCustomFields, activeContract, isMandatoryEmail]);

  const countryGlobalField = globalCustomFields?.find(
    globalItem => globalItem.key === 'hansefit_country',
  );
  const methods = useForm<AddEmployeeForm>({
    defaultValues: {
      ...customFieldsNames,
      ...globalCustomFieldsNames,
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: null,
      startDate: null,
      businessEmail: '',
      phoneNumber: '',
      customFields: {},
      globalCustomFields:
        company?.data?.globalCustomFields && !!countryGlobalField
          ? { [countryGlobalField.key]: 'DE' }
          : {},
      confirmFees: false,
      title: '',
      dialCode: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const genders = useGendersList();
  const employeeTitles = useEmployeeTitles();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState,
    setError,
    watch,
    clearErrors,
    trigger,
  } = methods;

  const { isValid, errors } = formState;

  const phoneNumber = watch('phoneNumber');
  useEffect(() => {
    if (phoneNumber) trigger('dialCode');
  }, [phoneNumber, trigger]);

  const isDisabledSubmitButton =
    !isValid ||
    (!isEmpty(errors.firstName) && !isEmpty(errors.lastName) && !isEmpty(errors.dateOfBirth));

  const employeeError = useMemo(() => {
    const error = { ...addEmployee.error };

    if (!addEmployee.error?.violations) {
      return error;
    }

    const violations = [...(error.violations || [])];
    const hasUniqueError = addEmployee.error?.violations?.find(
      e => e.code === 'NOT_UNIQUE_NAME_AND_BIRTHDATE_IN_COMPANY',
    );
    const firstNameMaxIndex = violations?.findIndex(
      e => e.propertyPath === 'firstName' && e.message.includes('Diese Zeichenkette ist zu lang'),
    );
    const lastNameMaxIndex = violations?.findIndex(
      e => e.propertyPath === 'lastName' && e.message.includes('Diese Zeichenkette ist zu lang'),
    );

    if (firstNameMaxIndex !== undefined && firstNameMaxIndex !== -1) {
      violations?.splice(firstNameMaxIndex, 1, {
        code: '',
        message: 'max',
        propertyPath: 'firstName',
      });
    }
    if (lastNameMaxIndex !== undefined && lastNameMaxIndex !== -1) {
      violations?.splice(lastNameMaxIndex, 1, {
        code: '',
        message: 'max',
        propertyPath: 'lastName',
      });
    }

    error.violations = [...violations];
    if (!hasUniqueError) {
      return error;
    }
    error.violations = [
      ...violations,
      { code: '', message: 'is_not_unique_name_and_birthdate', propertyPath: 'firstName' },
      { code: '', message: 'is_not_unique_name_and_birthdate', propertyPath: 'lastName' },
      { code: '', message: 'is_not_unique_name_and_birthdate', propertyPath: 'dateOfBirth' },
    ];
    return error;
  }, [addEmployee.error]);

  const addEmployeeViolationErrors = ValidationUtils.getViolationErrors({
    error: employeeError as PimcoreErrorResponse,
    scope: 'employee_management',
  });

  useEffect(() => {
    const subscription = watch((form, field) => {
      if (field.name === 'phoneNumber' && !form.phoneNumber && errors?.dialCode) {
        clearErrors('dialCode');
      }
      const uniqueFieldChange =
        field.name === 'firstName' || field.name === 'lastName' || field.name === 'dateOfBirth';

      if (field.type === 'change' && uniqueFieldChange) {
        const isFirstNameUniqueError =
          errors?.firstName?.message ===
          'employee_management.form.error.firstname.is_not_unique_name_and_birthdate';
        const isLastNameUniqueError =
          errors?.lastName?.message ===
          'employee_management.form.error.lastname.is_not_unique_name_and_birthdate';
        const isBirthDateUniqueError =
          errors?.dateOfBirth?.message ===
          'employee_management.form.error.dateofbirth.is_not_unique_name_and_birthdate';
        if (isFirstNameUniqueError) {
          clearErrors('firstName');
        }
        if (isLastNameUniqueError) {
          clearErrors('lastName');
        }
        if (isBirthDateUniqueError) {
          clearErrors('dateOfBirth');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [clearErrors, setError, errors, watch]);

  useAsyncViolationFeedback({
    withMessage: true,
    setError,
    errors: addEmployeeViolationErrors,
    watch,
    clearErrors,
    isSubmitting: formState.isSubmitting,
  });

  const handleOpen = useCallback(() => {
    if (!checkActivationLettersSettingsIsCorrect()) {
      return;
    }
    dispatch(
      EmployeeManagementActions.setAddEmployeeModalOpen({
        isOpen: true,
      }),
    );
  }, [dispatch, checkActivationLettersSettingsIsCorrect]);

  const handleClose = useCallback(() => {
    dispatch(
      EmployeeManagementActions.setAddEmployeeModalOpen({
        isOpen: false,
      }),
    );
    reset();
    dispatch(EmployeeManagementActions.resetAddEmployeeApi());
  }, [dispatch, reset]);

  const onSubmit = (data: AddEmployeeForm) => {
    const addEmployeeRequestBody = {
      ...data,
      businessEmail: data.businessEmail || null,
      companyId: activeCompany?.companyId || companiesNames.data[0].companyId,
      dateOfBirth: format(new Date(data.dateOfBirth as Date), 'yyyy-MM-dd'),
      startDate: format(getDate(data.startDate, activeContract?.regularStartDate), 'yyyy-MM-dd'),
      phoneNumber: data.phoneNumber ? (data.dialCode || '') + data.phoneNumber : '',
      title: data.title || null,
    };

    dispatch(EmployeeManagementActions.addEmployee(addEmployeeRequestBody));
  };

  useEffect(() => {
    if (company.fetchingStatus === FetchingStatus.IDLE && activeCompany?.companyId) {
      dispatch(CompanyActions.getCompany({ companyId: activeCompany.companyId }));
    }

    if (addEmployee.fetchingStatus === FetchingStatus.FULFILLED) {
      handleClose();
    }
  }, [dispatch, company, reset, handleClose, addEmployee, activeCompany?.companyId]);

  const isFirstNameError = useMemo(
    () =>
      errors?.firstName?.message ===
      'employee_management.form.error.firstname.is_not_unique_name_and_birthdate',
    [errors?.firstName?.message],
  );

  const isLastNameError = useMemo(
    () =>
      errors?.lastName?.message ===
      'employee_management.form.error.lastname.is_not_unique_name_and_birthdate',
    [errors?.lastName?.message],
  );

  const isDateOfBirthError = useMemo(
    () =>
      errors?.dateOfBirth?.message ===
      'employee_management.form.error.dateofbirth.is_not_unique_name_and_birthdate',
    [errors?.dateOfBirth?.message],
  );

  return !activeContract || !checkTypeParameter(activeContract, 'createEditEUPossible') ? null : (
    <>
      <Loader show={addEmployee.fetchingStatus === FetchingStatus.PENDING} />
      <CCPDefaultButton
        onClick={handleOpen}
        variant='contained'
        data-test-id='addNewEmployeeButton'>
        {formatMessage({
          id: 'employee_management.overview.cta.add_new',
          defaultMessage: 'Add new employee',
        })}
      </CCPDefaultButton>
      <FormProvider {...methods}>
        <Dialog
          component='form'
          open={addEmployeeModalOpen}
          onClose={handleClose}
          scroll='paper'
          onSubmit={handleSubmit(onSubmit)}
          data-test-id='addEmployeeModal'>
          <DialogTitle data-test-id='modalTitle'>
            {formatMessage({
              id: 'form.employee.modal.add_new.title',
              defaultMessage: 'Add new employee',
            })}
          </DialogTitle>
          {addEmployeeViolationErrors.length > 0 && (
            <ModalError
              headerErrorMessage={formatMessage({
                id: 'form.employee.modal.add_new.error_title',
                defaultMessage: 'Are the details correct?',
              })}
              descriptionErrorMessage={formatMessage({
                id: 'form.employee.modal.add_new.error_description',
                defaultMessage:
                  'If so, please contact your Hansefit representative to have a user created for you.',
              })}
            />
          )}
          <DialogContent>
            <Typography variant='subtitle1'>
              {formatMessage({
                id: 'form.employee.modal.add_new.content',
                defaultMessage: 'Fill out the form below to add new employee of your company',
              })}
              .
            </Typography>
            <Spacer height={48} />
            <Box>
              <Typography variant='h6'>
                {formatMessage({
                  id: 'form.employee.personal.title',
                  defaultMessage: 'Personal info',
                })}
              </Typography>
              <Spacer height={31} />
              <PersonalFieldsWrapper>
                <CCPSelect
                  control={control}
                  name='title'
                  label={formatMessage({
                    id: 'form.employee.field.title.label',
                    defaultMessage: 'Title',
                  })}
                  width={254}>
                  {employeeTitles.map(employeeTitle => (
                    <MenuItem
                      key={employeeTitle.value}
                      value={employeeTitle.value}
                      selected={employeeTitle.value === getValues('title')}
                      style={{ minHeight: 36 }}>
                      {employeeTitle.title}
                    </MenuItem>
                  ))}
                </CCPSelect>
                <CCPSelect
                  control={control}
                  name='gender'
                  required
                  label={formatMessage({
                    id: 'form.employee.field.gender.label',
                    defaultMessage: 'Gender',
                  })}
                  width={254}>
                  {genders.map(gender => (
                    <MenuItem
                      key={gender.value}
                      value={gender.value}
                      selected={gender.value === getValues('gender')}>
                      {gender.title}
                    </MenuItem>
                  ))}
                </CCPSelect>
                <Spacer height={42} />
                <CCPTextField
                  control={control}
                  name='firstName'
                  required
                  label={formatMessage({
                    id: 'form.employee.field.first_name.label',
                    defaultMessage: 'First name',
                  })}
                  width={254}
                  notShowErrorText={isFirstNameError}
                  isWarningError={isFirstNameError}
                />
                <CCPTextField
                  control={control}
                  name='lastName'
                  required
                  label={formatMessage({
                    id: 'form.employee.field.last_name.label',
                    defaultMessage: 'Last name',
                  })}
                  width={254}
                  notShowErrorText={isLastNameError}
                  isWarningError={isLastNameError}
                />
                <Spacer height={42} />

                <CCPDatePicker
                  control={control}
                  name='dateOfBirth'
                  minDate={getBirthDate('min')}
                  maxDate={getBirthDate('max')}
                  required
                  label={formatMessage({
                    id: 'form.employee.field.date_of_birth.label',
                    defaultMessage: 'Date of birth',
                  })}
                  isBirthdayPicker
                  width={254}
                  notShowErrorText={isDateOfBirthError}
                  isWarningError={isDateOfBirthError}
                />
                <StartDatePicker
                  control={control}
                  name='startDate'
                  isMonthPicker={checkIsMonthPicker(activeContract?.regularStartDate)}
                  minimumDate={prepareMinDateForStartPicker(activeContract)}
                  maximumDate={prepareMaxDateForStartPicker(activeContract)}
                  required
                  label={formatMessage({
                    id: 'form.employee.field.service_date.label',
                    defaultMessage: 'Service start date',
                  })}
                  width={254}
                />
                <Spacer height={42} />
              </PersonalFieldsWrapper>
            </Box>
            <Typography variant='h6'>
              {formatMessage({
                id: 'form.employee.contact.title',
                defaultMessage: 'Contact info',
              })}
            </Typography>
            <Spacer height={24} />
            <Stack direction='row' gap='8px'>
              <ErrorSvg htmlColor={theme.palette.neutralDark.light} fontSize='small' />
              <Box>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {formatMessage(
                    isMandatoryEmail
                      ? {
                          id: 'form.employee.contact.mandatory.sublabel.title',
                          defaultMessage: 'Email is mandatory',
                        }
                      : {
                          id: 'form.employee.contact.optional.sublabel.title',
                          defaultMessage: 'Activation letter will be send to portal user',
                        },
                  )}
                </Typography>
                <Spacer height={4} />
                <Typography variant='subtitle2'>
                  {formatMessage(
                    isMandatoryEmail
                      ? {
                          id: 'form.employee.contact.mandatory.sublabel',
                          defaultMessage:
                            'The activation letter is send directly to the employee. To change this configuration, go to {settings}.',
                        }
                      : {
                          id: 'form.employee.contact.optional.sublabel',
                          defaultMessage:
                            'To send the activation letter directly to the employee, change the configuration in the {settings}.',
                        },
                    {
                      settings: (
                        <Link to={`/${language}/settings/1`} onClick={handleClose}>
                          {formatMessage({
                            id: 'navigation.settings',
                            defaultMessage: 'Settings',
                          })}
                        </Link>
                      ),
                    },
                  )}
                </Typography>
              </Box>
            </Stack>
            <Spacer height={32} />
            <PersonalFieldsWrapper>
              <CCPTextField
                control={control}
                name='businessEmail'
                required={isMandatoryEmail}
                label={formatMessage({
                  id: 'form.employee.field.corporate_email.label',
                  defaultMessage: 'Corporate email',
                })}
              />
              <Typography variant='subtitle2' marginTop='8px'>
                {formatMessage({
                  id: 'form.employee.field.corporate_email.subtitle',
                  defaultMessage: 'Email-adresses can only be used once.',
                })}
              </Typography>
            </PersonalFieldsWrapper>
            <Spacer height={31} />
            <PersonalFieldsWrapper>
              <CCPSelect
                control={control}
                name='dialCode'
                label={`${formatMessage({
                  id: 'form.employee.field.dialCode.label',
                  defaultMessage: 'Prefix',
                })}`}
                width={104}>
                {dialCodes.map(({ dialCode }) => (
                  <MenuItem
                    key={dialCode}
                    value={dialCode}
                    selected={dialCode === getValues('dialCode')}>
                    {dialCode}
                  </MenuItem>
                ))}
              </CCPSelect>
              <CCPTextField
                control={control}
                name='phoneNumber'
                label={formatMessage({
                  id: 'form.employee.field.phone.label',
                  defaultMessage: 'Phone number',
                })}
                width={404}
                type='integer'
              />
              <Spacer height={42} />
            </PersonalFieldsWrapper>
            <GlobalCustomFields isAddressFields />
            <GlobalCustomFields />
            <CustomFields />
            <Spacer height={32} />
            <CCPLabelCheckbox
              control={control}
              name='confirmFees'
              label={formatMessage({
                id: 'form.employee.field.fees.label',
                defaultMessage: `I confirm that by adding the employee, the fees specified in the contract will become due.`,
              })}
            />
          </DialogContent>
          <DialogActions>
            <CCPDefaultButton
              variant='outlined'
              onClick={handleClose}
              data-test-id='backToListButton'>
              {formatMessage({
                id: 'form.employee.cta.back',
                defaultMessage: 'Back to list',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton
              disabled={isDisabledSubmitButton}
              variant='contained'
              type='submit'
              data-test-id='sendInviteButton'>
              {formatMessage({
                id: 'form.employee.cta.send',
                defaultMessage: 'Send invite',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddEmployeeModal;
