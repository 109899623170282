import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { RootState, BaseErrorResponse } from 'types';

const updateLandingSettings = createAsyncThunk<
  any,
  { landingPageHeadline?: string },
  { state: RootState }
>(
  'settings/updateLandingSettings',
  async ({ landingPageHeadline }, { rejectWithValue, getState }) => {
    const state = getState();
    const companyId = state.company.activeCompany?.companyId;
    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}/landing`;

    const axios = await axiosWithConfig();

    const patchBody = { landingPageHeadline: landingPageHeadline || '' };
    try {
      const { data } = await axios.patch(endpoint, patchBody, {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      });
      showToast('success', {
        id: 'toast.settings.data_saved.success',
        defaultMessage: 'Data was saved successfully',
      });
      return data;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      showToast('error', {
        id: 'toast.registration_settings.set.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);

export default updateLandingSettings;
