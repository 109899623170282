import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from '@store/globalState/interfaces';

export const setActivationLettersPopupState: CaseReducer<GlobalState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.guardPopups.activationLetters = action.payload;
};

export const showActivationLettersAddUserPopup: CaseReducer<GlobalState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.guardPopups.showActivationLettersAddUser = action.payload;
};

export const setRegistrationDeadlinePopupState: CaseReducer<GlobalState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.guardPopups.registrationDeadline = action.payload;
};
