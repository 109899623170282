import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DefaultTextField } from '../DefaultTextField.styles';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import formatFormFieldLabels from '@utils/formatFormFieldLabels';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  disabled?: boolean;
  width?: number | string;
  reset?: boolean;
  type?: 'string' | 'integer' | 'float';
  notShowErrorText?: boolean;
  helperTextId?: string;
  isCustomHelperText?: boolean;
  isWarningError?: boolean;
}

const CCPTextField = ({
  label,
  control,
  name,
  reset,
  width,
  type,
  disabled,
  notShowErrorText = false,
  isCustomHelperText = false,
  isWarningError = false,
  helperTextId,
  ...props
}: InputFieldProps & TextFieldProps) => {
  const intl = useIntl();
  const [isAutoFill, setIsAutoFill] = useState(false);
  const { resetField, setValue, trigger } = useFormContext();
  const handleClear = useCallback(() => {
    resetField(name);
  }, [resetField, name]);

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (type && type !== 'integer' && type !== 'float' && type !== 'string') {
        //old strange logic, idk why, but it is global component I can't check all textFields
        setValue(name, e.currentTarget.value.slice(0, -1));
      }
      const isValidIntegerValue = type === 'integer' && !!e.currentTarget.value.match(/^\d*$/);
      const isValidFloatValue =
        type === 'float' &&
        (!!e.currentTarget.value.match(/^\d+(\.\d{0,4})?$/) || e.currentTarget.value === '');

      if (type === 'string' || type === undefined || isValidIntegerValue || isValidFloatValue) {
        setValue(name, e.currentTarget.value);
        if (type !== undefined) {
          await trigger(name);
        }
      }
    },
    [trigger, type, name, setValue],
  );

  const properties = useMemo(() => (type ? { onChange: handleChange } : {}), [handleChange, type]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        let errorText = '';
        if (fieldState.error?.message && !notShowErrorText) {
          if (isCustomHelperText && helperTextId) {
            errorText = intl.formatMessage({
              id: helperTextId,
              defaultMessage: defaultTextfieldError[helperTextId],
            });
          } else {
            errorText = intl.formatMessage({
              id: fieldState.error.message,
              defaultMessage:
                defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
            });
          }
        }

        return (
          <DefaultTextField
            {...props}
            {...fieldProps}
            inputRef={field.ref}
            disabled={disabled}
            {...properties}
            width={width}
            isWarningError={isWarningError}
            error={!!fieldState.error}
            helperText={errorText && <span data-test-id='validationErrorText'>{errorText}</span>}
            required={false}
            label={formatFormFieldLabels(label, props.required)}
            data-test-id={`${name}Container`}
            type='text'
            InputLabelProps={{ shrink: isAutoFill || undefined }}
            InputProps={{
              ...props.InputProps,
              ...{
                onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
                  e.animationName === 'mui-auto-fill' && setIsAutoFill(true);
                },
                onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
                  e.animationName === 'mui-auto-fill-cancel' && setIsAutoFill(false),
                onFocus: () => setIsAutoFill(false),
                endAdornment: reset ? (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClear} edge='end'>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  props.InputProps?.endAdornment
                ),
              },
            }}
          />
        );
      }}
    />
  );
};

export default CCPTextField;
