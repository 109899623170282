import { FetchingStatus } from 'types';

import { StatsState } from './interfaces';

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();

export const initialState: StatsState = {
  checkIns: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
    filters: {
      endYear: currentYear,
      startYear: currentYear,
      endMonth: currentMonth,
      startMonth: currentMonth,
    },
  },
  coupons: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
    filters: {
      endYear: currentYear,
      startYear: currentYear,
      endMonth: currentMonth,
      startMonth: currentMonth,
    },
  },
  activeMembers: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
    filters: {
      endYear: currentYear,
      startYear: currentYear,
      endMonth: currentMonth,
      startMonth: currentMonth,
    },
  },
};
