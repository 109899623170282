import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig, patchHeaders } from 'api';
import { RootState, SecurityResponse, UserManagementBaseResponse } from 'types';

const updateMFASetting = createAsyncThunk<SecurityResponse, boolean, { state: RootState }>(
  'security/updateMFASetting',
  async (value, { rejectWithValue, getState }) => {
    const state = getState();

    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${state.company.activeCompany?.companyId}/security`;
    const patchData = { is2FAEnabled: value };
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.patch(endpoint, patchData, patchHeaders);

      return data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.settings.update.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);

export default updateMFASetting;
