import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import distinct from '@utils/distinct';
import { axiosWithConfig } from 'api';
import {
  UserManagementBaseResponse,
  GetCheckInsRequestParams,
  ActiveMembersTableProps,
} from 'types';

export const getActiveMembers = createAsyncThunk<
  ActiveMembersTableProps[],
  GetCheckInsRequestParams
>('stats/getActiveMembers', async ({ companyId, startMonth, endMonth }, { rejectWithValue }) => {
  const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}graphql`;
  const body = {
    query: `
		{
			CompanyActiveMembers(filter:{company_id:{eq:"${companyId}"},date:{gte:"${startMonth}",lte:"${endMonth}"},status:{eq:["E","A"]}}){
				start_month, end_month
				data { customer_id, first_name, last_name, registration_date, status }
			}
		}`,
  };
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.post(endpoint, body);

    const filteredData = distinct(data.data.CompanyActiveMembers.data, 'customer_id');

    return filteredData;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;
    showToast('error', {
      id: 'toast.active_members.get.error',
      defaultMessage: error.message,
    });
    return rejectWithValue(error.response?.data);
  }
});

export default getActiveMembers;
