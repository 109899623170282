import { format, lastDayOfMonth } from 'date-fns';

export const getFirstDayOfMonth = (year: number, specifiedMonth: number) => {
  const realMonth = specifiedMonth + 1;
  const startMonthString = realMonth < 10 ? `0${realMonth}` : `${realMonth}`;
  return format(new Date(`${year}-${startMonthString}-01`), 'yyyy-MM-dd');
};

export const getLastDayOfMonth = (year: number, specifiedMonth: number) => {
  const realMonth = specifiedMonth + 1;
  const month = realMonth < 10 ? `0${realMonth}` : `${realMonth}`;
  return format(lastDayOfMonth(new Date(`${year}-${month}-01`)), 'yyyy-MM-dd');
};
