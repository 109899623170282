import ReactRouterPrompt from 'react-router-prompt';
import { useAppSelector } from '@store/index';
import GuardActivationLetters from './Dialogs/GuardActivationLetters';
import { FetchingStatus } from '@api/interfaces';
import { useCallback } from 'react';
import GuardRegistrationDeadline from './Dialogs/GuardRegistrationDeadline';
const RouterGuard = () => {
  const {
    guardPopups: { activationLetters, registrationDeadline },
  } = useAppSelector(state => state.globalState);

  const settingsFetchingStatus = useAppSelector(state => state.settings.settings.fetchingStatus);
  const signupFetchingStatus = useAppSelector(state => state.settings.selfRegistrationFetching);

  const blocker = useCallback(() => {
    if (
      settingsFetchingStatus !== FetchingStatus.PENDING &&
      signupFetchingStatus !== FetchingStatus.PENDING
    ) {
      return activationLetters || registrationDeadline;
    }

    return false;
  }, [settingsFetchingStatus, signupFetchingStatus, activationLetters, registrationDeadline]);

  return (
    <ReactRouterPrompt when={blocker}>
      {({ isActive, onConfirm, onCancel }) => {
        if (isActive) {
          if (activationLetters) {
            return <GuardActivationLetters onConfirm={onConfirm} onCancel={onCancel} />;
          }
          if (registrationDeadline) {
            return <GuardRegistrationDeadline onConfirm={onConfirm} onCancel={onCancel} />;
          }
        }
      }}
    </ReactRouterPrompt>
  );
};

export default RouterGuard;
