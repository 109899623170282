import { Box } from '@mui/material';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { CompanyRoleStatusState, FetchingStatus } from 'types';
import qs from 'qs';

import { AuthenticationActions } from 'features/authentication/store';
import { GuestLayoutStyled } from './GuestLayout.styles';
import { getCompanyTokenData } from 'utils/authentication';
import { useLanguage } from '@hooks/useLanguage';

export interface UnauthenticatedLayoutProps {
  children: JSX.Element | JSX.Element[];
  leftNavigation: JSX.Element;
}

const GuestLayout = (props: UnauthenticatedLayoutProps): JSX.Element => {
  const location = useLocation();
  const { children, leftNavigation } = props;
  const dispatch = useAppDispatch();
  const authentication = useSelector(AuthenticationSelectors.getAuthentication);
  const navigate = useNavigate();
  const activeLanguage = useLanguage();

  const isAuthenticated = useCallback(
    () =>
      authentication.fetchingStatus === FetchingStatus.FULFILLED && authentication.authenticated,
    [authentication],
  );

  const shouldSignOut = useCallback(() => {
    const querySearchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    //it is a crutches to fix CCP-568 for correct handle situation where user get a link to complete registration(second link)
    //unfortunately I don't have a deeper knowlege in this project and we need to fix this problem maybe in a future
    //second part of this crutch is located in Login/index.tsx
    //oleksandr.bielik - 26.10.2022
    const loginButtonPressed = localStorage.getItem('loginWasPressed');
    return (
      querySearchParams &&
      ((querySearchParams.companyId && querySearchParams.signUpKey) ||
        (querySearchParams.invite &&
          ['success', 'error'].includes(querySearchParams.invite as string) &&
          !loginButtonPressed))
    );
  }, [location]);

  const isUserWithoutRoles = useCallback(
    (companyTokenData: CompanyRoleStatusState[]) =>
      companyTokenData.length === 0 ||
      companyTokenData.filter(item => item.role.length === 0).length === companyTokenData.length,
    [],
  );

  const navigateToLastOpenedPageOrDashboard = useCallback(
    (companyTokenData: CompanyRoleStatusState[]) => {
      const lastOpenedPage = window.localStorage.getItem('lastOpenedPathname');
      const activeCompanyId = window.localStorage.getItem('activeCompanyId');
      if (lastOpenedPage && activeCompanyId) {
        window.localStorage.removeItem('lastOpenedPathname');
        navigate(lastOpenedPage);
      } else {
        navigate(
          `/${activeLanguage}/dashboard/${
            companyTokenData.length === 1 ? companyTokenData[0].companyId : ''
          }`,
        );
      }
    },
    [navigate, activeLanguage],
  );

  const handleSessionExpired = useCallback(() => {
    const activeCompanyId = window.localStorage.getItem('activeCompanyId') || '';
    dispatch(AuthenticationActions.signOut());
    window.localStorage.setItem('lastOpenedPathname', window.location.pathname);
    window.localStorage.setItem('activeCompanyId', activeCompanyId);
    dispatch(AuthenticationActions.resetAuthState());
    navigate(`/${activeLanguage}/login?session=expired`);
  }, [dispatch, navigate, activeLanguage]);

  const redirectToDashboard = useCallback(async () => {
    try {
      const resp = await getCompanyTokenData();
      if (isUserWithoutRoles(resp)) {
        dispatch(AuthenticationActions.signOut());
        navigate(`/${activeLanguage}/login?auth=false`);
      } else {
        navigateToLastOpenedPageOrDashboard(resp);
      }
    } catch (err) {
      handleSessionExpired();
    }
  }, [
    dispatch,
    navigate,
    activeLanguage,
    isUserWithoutRoles,
    navigateToLastOpenedPageOrDashboard,
    handleSessionExpired,
  ]);

  useEffect(() => {
    if (!isAuthenticated()) {
      return;
    }
    if (shouldSignOut()) {
      dispatch(AuthenticationActions.signOut());
      dispatch(AuthenticationActions.resetAuthState());
    } else {
      localStorage.removeItem('loginWasPressed');
      redirectToDashboard();
    }
  }, [dispatch, isAuthenticated, shouldSignOut, redirectToDashboard]);

  return (
    <GuestLayoutStyled>
      <Box className='container'>
        {leftNavigation}
        <Box className='content'>{children}</Box>
      </Box>
    </GuestLayoutStyled>
  );
};

export default GuestLayout;
