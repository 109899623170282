import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ICompanyAsset, PimcoreErrorResponse, RootState } from 'types';
import { axiosWithConfig, patchHeaders } from 'api';
import { UploadAssetsParams } from '@features/shared/companyAssets/store/interfaces';
import { updateCompanyAssets } from 'features/company/store/actions/sync/updateCompanyAssets.actions';
import { CompanyActions } from 'features/company/store';
import showToast from '@utils/showToast';

export const addCompanyAssets = createAsyncThunk<
  boolean,
  UploadAssetsParams,
  { state: RootState; rejectValue: PimcoreErrorResponse }
>(
  'companyManagement/addCompanyAssets',
  async ({ fileType, filename, originalFilename }, { rejectWithValue, getState, dispatch }) => {
    const currentState = getState();
    const companyId =
      currentState.company.activeCompany?.companyId ||
      currentState.company.companiesNames.data[0].companyId;

    const axios = await axiosWithConfig();
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/assets/${fileType}`;

    const params = {
      filename,
      originalFilename,
    };

    try {
      const { assetId } = (await axios.put(endpoint, params)).data;
      const patchResp = (await axios.patch(endpoint, { assetId }, patchHeaders)).data;

      dispatch(
        CompanyActions.updateAssets(
          updateCompanyAssets(fileType, patchResp[fileType] as ICompanyAsset).payload,
        ),
      );

      return true;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      showToast('error', {
        id: 'toast.settings.logo_upload.error',
        defaultMessage: 'Something went wrong',
      });

      return rejectWithValue(data);
    }
  },
);
