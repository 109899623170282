import { AxiosError } from 'axios';
import { axiosWithConfig } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetUserInfoRequestParams,
  IUserInfoRequest,
  RootState,
  UserManagementBaseResponse,
} from 'types';

export const getUserInfo = createAsyncThunk<
  { companyId: number | undefined; userData: IUserInfoRequest },
  GetUserInfoRequestParams,
  { state: RootState }
>('authentication/getOwnUser', async ({ companyId }, { rejectWithValue, getState }) => {
  const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + `getOwnUserData`;
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.get(endpoint);

    const state = getState();
    return {
      companyId: companyId || state.company.activeCompany?.companyId,
      userData: data.detail as IUserInfoRequest,
    };
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;

    return rejectWithValue(error.response?.data);
  }
});
