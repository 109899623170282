import { FiltersProps, StatsState } from 'types';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export const updateCheckInsFilters: CaseReducer<StatsState, PayloadAction<FiltersProps>> = (
  state,
  { payload },
) => {
  state.checkIns.filters = payload;
};

export const updateCouponFilters: CaseReducer<StatsState, PayloadAction<FiltersProps>> = (
  state,
  { payload },
) => {
  state.coupons.filters = payload;
};

export const updateActiveMembersFilters: CaseReducer<StatsState, PayloadAction<FiltersProps>> = (
  state,
  { payload },
) => {
  state.activeMembers.filters = payload;
};
