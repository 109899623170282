import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DefaultTextField } from '../TextField/DefaultTextField.styles';
import { ReactElement } from 'react';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import formatFormFieldLabels from '@utils/formatFormFieldLabels';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  children: ReactElement<any, any>[];
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  className?: string;
  required?: boolean;
}

const CCPSelect = ({
  label,
  control,
  name,
  children,
  width,
  height,
  disabled,
  className,
  required = false,
}: InputFieldProps) => {
  const intl = useIntl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <DefaultTextField
          {...field}
          disabled={disabled}
          width={width}
          className={className}
          height={height}
          error={!!fieldState.error}
          helperText={
            fieldState.error?.message ? (
              <span data-test-id='validationErrorText'>
                {intl.formatMessage({
                  id: fieldState.error?.message,
                  defaultMessage:
                    defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                })}
              </span>
            ) : undefined
          }
          label={formatFormFieldLabels(label, required)}
          data-test-id={`${name}Container`}
          select
          SelectProps={{
            IconComponent: ArrowDropDownIcon,
          }}>
          {children}
        </DefaultTextField>
      )}
    />
  );
};

export default CCPSelect;
