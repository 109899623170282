import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { RootState, BaseErrorResponse } from 'types';

export const setRegistrationIdentifier = createAsyncThunk<
  any,
  { signupID: string },
  { state: RootState }
>('settings/setRegistrationIdentifier', async ({ signupID }, { rejectWithValue, getState }) => {
  const state = getState();
  const companyId = state.company.activeCompany?.companyId;
  const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}/setup`;

  const axios = await axiosWithConfig();

  try {
    await axios.patch(
      endpoint,
      {
        signupIdentifier: signupID,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    );
    showToast('success', {
      id: 'toast.settings.data_saved.success',
      defaultMessage: 'Data was saved successfully',
    });
    return true;
  } catch (e) {
    const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
    showToast('error', {
      id: 'toast.registration_settings.set.error',
      defaultMessage: error.message,
    });
    return rejectWithValue(error.response?.data);
  }
});
